<template>
	<div>
		<div style="margin: 16px;font-size: 17px">
			<h1>{{ title }}</h1>
			<div style="margin: 10px 0;line-height: 180%;color: #555;font-size: 13px" v-html="description"></div>
		</div>
		<div class="common_block">
			<van-button type="warning" block @click="nextStep">我已阅读并同意</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'account_lock',
		data() {
			return {
				title: "",
				description: ""
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.$axios({
					method: 'post',
					url: 'account_open/term',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.title = res.data.title
						this.description = res.data.description
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
		methods: {
			
			nextStep() {
				this.$router.replace('/account/sign?token=' + this.token)
			}
		},
	}
</script>